@import '_partials/reset-css';

html {
    background: #000;
}

body {
    margin: 5px 0 0 0;
    font: normal normal normal 10pt/1.3em Verdana, Arial, Helvetica, sans-serif;
    color: #000;
    background: transparent;

    .inner {
        width: 100%;
        max-width: 766px;
        margin: 0 auto;
    }

    .main-content {
        background: #fff;
        border-radius: 10px; 
        padding: 1px 10px 10px;
    }
}

.slideshow-wrapper {
    display: block;
    width: 100%; 
    max-width: 400px;
    margin: 0.9em auto; 
}

.slide {
    display: none;
}

h1 {
    font-size: 14pt;
    line-height: 1em;
    background: #000 url(../img/divider.jpg) no-repeat left center;
    padding: 10px 0 10px 30px;
    color: #fff;
    margin: 1.6em 0;
    border-radius: 5px;
}

h2 {
    font-size: 12pt; 
    font-weight: bold;
    text-align: center;
    margin: 1em 0;
    line-height: 1.6em;
}

a {
    color: #333;
    text-decoration: underline;
    &:link, 
    &:visited {
        color: #333;
    }
    &:hover {
        color: #666;
    }
    
    &.footerNav {
        color: #ccc;
        font-size: 8pt;
        text-decoration: none;
        &:link, 
        &:visited {
            color: #ccc;
        }
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }

    &.button {
        background-color: #cc0000;
        color: #fff;
        border: 1px solid #cc0000;
        display: inline-block; 
        padding: 10px 20px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        border-radius: 5px;

        &:hover {
            background: #fff;
            color: #cc0000;
        }
    }
}


p {
    margin: 0.9em 0;
}

aside.right {
    max-width: 200px;
    float: right; 
    margin-left: 10px;
}

.center {
    text-align: center;
    margin: 0.9 auto;
}

span.sitename {
    font-weight: bold;
}

.cols-2 {
    column-count: 2;
    column-gap: 40px;
}
.clearfix {
    display: block;
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

footer {
    color: #f1f1f1;
    .copyright {
        text-align: center;
    }
}

* html .clearfix {
    height: 1%;
}